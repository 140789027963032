import { range } from 'lodash';
import { useRef, useState } from 'react';

import { Input } from '@jernia/shared/components/input/input';

type Props = {
  onComplete: (code: string) => void;
  disabled?: boolean;
  type?: 'text' | 'password';
  digits?: number;
  hasError?: boolean;
  onNotValid?: (code: string) => void;
};

export function CodeInput({
  disabled,
  onComplete,
  onNotValid,
  type = 'text',
  digits = 6,
  hasError,
}: Props) {
  const inputs = useRef<Array<HTMLInputElement | null>>([]);

  const [value, onChange] = useState<string[]>(range(0, digits).map(() => ''));

  return (
    <div className="flex space-x-2">
      {range(0, digits).map((i) => {
        return (
          <Input
            type={type}
            kind={hasError ? 'danger' : 'default'}
            key={i}
            className="text-center"
            containerClassName="flex align-middle justify-center"
            value={value[i]}
            ref={(el) => {
              inputs.current[i] = el;
            }}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={i === 0}
            min={0}
            max={9}
            pattern="\d*"
            disabled={disabled}
            showPasswordToggle={false}
            onFocus={(e) => {
              e.currentTarget.select();
            }}
            onKeyDown={(e) => {
              switch (e.code) {
                case 'Backspace': {
                  if (e.currentTarget.value === '') {
                    e.preventDefault();
                    inputs.current[i - 1]?.focus();
                  }
                }
              }
            }}
            onChange={(e) => {
              const inputValue = e.currentTarget.value.replace(/[^0-9]/g, '');
              if (inputValue.length >= 1) {
                e.preventDefault();

                onChange((cur) => {
                  let next = [...cur];
                  const maxLength = cur.length;
                  // Spread the new input over the current one. If the user
                  // pastes this will ensure every field after the currently
                  // focused will have its value updated.
                  next.splice(i, inputValue.length, ...inputValue.split(''));
                  next = next.slice(0, maxLength);

                  const valid = next.every((num) => {
                    const asInt = Number(num);
                    return num && asInt >= 0 && asInt <= 9;
                  });

                  if (valid) {
                    onComplete(next.join(''));
                  } else {
                    onNotValid?.(next.join(''));
                  }

                  return next;
                });
                inputs.current[i + 1]?.focus();
              } else {
                onChange((cur) => {
                  const next = [...cur];
                  next[i] = inputValue;
                  return next;
                });
              }
            }}
          />
        );
      })}
    </div>
  );
}
