import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useRouter } from 'next/dist/client/router';
import Head from 'next/head';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import Button from '@jernia/shared/components/button/button';
import { CodeInput } from '@jernia/shared/components/code-input/code-input';
import {
  ArrowLeftIcon,
  ChevronDownIcon,
  SpinnerLoaderIcon,
  StoreIcon,
} from '@jernia/shared/components/icons/icons';
import { PictoCard } from '@jernia/shared/components/picto-card/picto-card';

import { useAuthStore } from '@jernia/shared/state/auth';

import { config } from '@jernia/shared/lib/config';

import { Logo } from '../../components/logo/logo';
import { StoreSelectModal } from '../../components/modal/store-select-modal';
import { SplashScreen } from '../../components/splash-screen/splash-screen';

import { PointOfService } from '@jernia/shared/types/rest/stores';

function LoginStore({
  initialStore,
  storeToken,
}: {
  initialStore: PointOfService;
  storeToken: string;
}) {
  const router = useRouter();

  const { loginSuccess } = useAuthStore();

  const [step, setStep] = useState('code');

  const [store, setStore] = useState<PointOfService>(initialStore);

  const [employeeId, setEmployeeId] = useState('');
  const [showStoreSelectModal, setShowStoreSelectModal] = useState(false);

  const loginMutation = useMutation(
    async ({ password }: { password: string }) => {
      const response = await fetch(`/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          storeId: store.vismaStoreId,
          employeeId: employeeId,
          password: password,
        }),
      });

      if (!response.ok) {
        throw await response.json();
      }

      return await response.json();
    },
    {
      onSuccess: async (result) => {
        localStorage.setItem('storeToken', storeToken);

        loginSuccess(result);

        await router.push('/');
      },
    }
  );

  function renderStep() {
    switch (step) {
      case 'code':
      default:
        return (
          <div className="space-y-4">
            <h2 className="text-center text-lg font-medium">
              Fyll inn ansattnummer
            </h2>
            <CodeInput
              key="code"
              onComplete={(employeeId) => {
                setEmployeeId(employeeId);
                setStep('password');
              }}
            />
          </div>
        );

      case 'password':
        return (
          <div className="space-y-4">
            <h2 className="text-center text-lg font-medium">
              Fyll inn passord
            </h2>

            <CodeInput
              key="password"
              type="password"
              onComplete={(password) => {
                loginMutation.mutate({ password });
              }}
            />

            {loginMutation.isError ? (
              <div className="rounded bg-danger-100 p-2 text-center text-danger-800">
                Feil ansattnummer eller passord
              </div>
            ) : null}

            <Button
              kind="secondary-dark"
              size="small"
              onClick={() => {
                setStep('code');
              }}
              className="w-full"
              leftIcon={<ArrowLeftIcon />}
            >
              Logg inn med en annen bruker
            </Button>
          </div>
        );
    }
  }

  return (
    <>
      <StoreSelectModal
        isOpen={showStoreSelectModal}
        onChange={(newStore) => {
          setStore(newStore);
          setShowStoreSelectModal(false);
        }}
        onClose={() => setShowStoreSelectModal(false)}
      />
      <Head>
        <title>Logg inn - Jernia Hub</title>
      </Head>
      <div className="flex h-screen w-screen flex-col items-center justify-center space-y-4 bg-primary-800">
        <div className="shrink-0">
          <motion.div layoutId="logo" className="relative z-20 h-12 w-full">
            <Logo className="size-full" />
          </motion.div>
        </div>

        <div className="relative">
          {loginMutation.isLoading && (
            <div className="absolute inset-0 z-20 flex items-center justify-center">
              <SpinnerLoaderIcon className="animate-spin text-white" size={6} />
            </div>
          )}

          <motion.div
            className={classNames(
              'mx-4 max-w-sm space-y-4 overflow-hidden rounded bg-white p-8 shadow transition-opacity',
              {
                'opacity-30': loginMutation.isLoading,
              }
            )}
            initial={{
              height: 0,
            }}
            animate={{
              height: 'auto',
            }}
          >
            <PictoCard
              icon={<StoreIcon className="text-primary" />}
              rightIcon={<ChevronDownIcon />}
              onClick={() => setShowStoreSelectModal(true)}
            >
              <PictoCard.Overline>Din Jerniabutikk</PictoCard.Overline>
              <PictoCard.Title>{store.name}</PictoCard.Title>
            </PictoCard>

            <div className="border-t border-neutral-500 px-4 pt-4">
              {renderStep()}
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
}

export default function LoginPage() {
  const router = useRouter();
  const storeToken = Array.isArray(router.query.storeId)
    ? router.query.storeId[0]
    : null;

  if (
    router.isReady &&
    !storeToken &&
    typeof localStorage !== 'undefined' &&
    localStorage.getItem('storeToken')
  ) {
    router.push(`/login/${localStorage.getItem('storeToken')}`);
  }

  const storeQuery = useQuery<PointOfService, Error>(
    ['loginStore', storeToken],
    async () => {
      const response = await fetch(
        `${config.apiBaseUrl}/stores/validateB2HStoreId?storeId=${storeToken}`
      );

      if (!response.ok) {
        throw new Error('Fant ingen butikk med gitt nøkkel.');
      }

      return response.json();
    },
    {
      enabled: Boolean(storeToken),
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (!storeToken) {
    return (
      <>
        <Head>
          <title>Jernia Hub</title>
        </Head>
        <div className="flex h-screen w-screen flex-col items-center justify-center space-y-4 bg-primary-800">
          <div className="shrink-0">
            <motion.div layoutId="logo" className="relative z-20 h-12 w-full">
              <Logo className="size-full" />
            </motion.div>
          </div>
          <motion.div
            className="mx-4 max-w-sm space-y-4 overflow-hidden rounded bg-white p-8 text-center leading-6 shadow"
            initial={{
              height: 0,
            }}
            animate={{
              height: 'auto',
            }}
          >
            <h1 className="text-xl font-semibold">
              Logg inn i Jernia Hub med butikkens unike URL
            </h1>
            <p>Ta kontakt med butikkleder for mer informasjon.</p>
          </motion.div>
        </div>
      </>
    );
  }

  if (storeQuery.isError) {
    return (
      <>
        <Head>
          <title>Fant ikke butikk - Jernia Hub</title>
        </Head>
        <div className="flex h-screen w-screen flex-col items-center justify-center space-y-4 bg-primary-800">
          <div className="w-full max-w-md rounded bg-white p-4 text-center font-semibold text-danger shadow">
            {storeQuery.error.message}
          </div>
        </div>
      </>
    );
  }

  if (storeQuery.isLoading) {
    return (
      <>
        <Head>
          <title>Laster... - Jernia Hub</title>
        </Head>
        <SplashScreen />
      </>
    );
  }

  return storeQuery.data ? (
    <LoginStore initialStore={storeQuery.data} storeToken={storeToken} />
  ) : null;
}
