import classNames from 'classnames';
import React from 'react';
import { useContext } from 'react';
import { useMemo } from 'react';

import { IconSizeProvider } from '../icons/context';

type Props = {
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  kind?: 'default' | 'light-blue';
  onClick?: () => void;
};

type PictoContextValue = {
  disabled: boolean;
  isClickable: boolean;
};

const PictoContext = React.createContext<PictoContextValue>({
  disabled: false,
  isClickable: false,
});

export function PictoCard({
  icon,
  rightIcon,
  children,
  disabled,
  kind = 'default',
  onClick,
}: Props) {
  const isClickable = typeof onClick === 'function';
  const contextValue = useMemo<PictoContextValue>(() => {
    return { disabled: Boolean(disabled), isClickable };
  }, [disabled, isClickable]);

  const inner = (
    <PictoContext.Provider value={contextValue}>
      {icon && (
        <div
          className={classNames(
            'flex h-12 w-12 flex-none items-center justify-center rounded-full ',
            {
              'bg-primary-100': kind === 'default',
              'bg-primary-300 text-primary-700': kind === 'light-blue',
            }
          )}
        >
          <IconSizeProvider size={6}>{icon}</IconSizeProvider>
        </div>
      )}
      <div className="grow">{children}</div>
      {rightIcon ? (
        <div
          className={classNames(
            'flex h-12 w-12 flex-none items-center justify-center rounded-full',
            {
              'group-hover:bg-primary-100': !disabled,
            }
          )}
        >
          <IconSizeProvider size={6}>{rightIcon}</IconSizeProvider>
        </div>
      ) : null}
    </PictoContext.Provider>
  );

  if (onClick) {
    return (
      <button
        className={classNames(
          'group min-h-16 px-4 flex w-full items-center space-x-4 rounded-none text-left focus:bg-primary-100 focus:outline-none disabled:text-neutral-700',
          {
            'py-4': !!icon,
          }
        )}
        onClick={onClick}
        disabled={disabled}
      >
        {inner}
      </button>
    );
  }

  return (
    <div
      className={classNames('group min-h-16 flex items-center space-x-4 px-4', {
        'py-4': !!icon,
      })}
    >
      {inner}
    </div>
  );
}

PictoCard.Title = function PictoCardTitle({
  color,
  size = 'medium',
  children,
}: {
  color?: 'primary' | 'success';
  size?: 'small' | 'medium';
  children: React.ReactNode;
}) {
  const { disabled, isClickable } = useContext(PictoContext);

  if (!color && isClickable) {
    color = 'primary';
  }

  return (
    <div
      className={classNames('text-sm font-semibold', {
        'text-primary': color === 'primary' && !disabled,
        'text-success': color === 'success' && !disabled,
        'text-text-700': disabled,
        'text-xs': size === 'small',
      })}
    >
      {children}
    </div>
  );
};

PictoCard.Overline = function PictoCardOverline({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="text-sm font-medium">{children}</div>;
};

PictoCard.Content = function PictoCardContent({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="text-sm">{children}</div>;
};
